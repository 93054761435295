import { Alert, Snackbar } from '@mui/material';
import { useRecoilState } from 'recoil';
import { SnackBarStoreType, snackBarStore } from 'src/recoil/store/snackBar';

// 상태 메시지를 띄워줄 스낵바 컴포넌트
export default function CustomSnackbar() {
  // 스낵바 알람 관련 상태
  const [message, setMessage] = useRecoilState<SnackBarStoreType>(snackBarStore);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage((prev) => ({ ...prev, open: false }));
  };

  return (
    <Snackbar open={message.open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={() => setMessage((prev) => ({ ...prev, open: false }))} severity={message.type} sx={{ width: '100%' }}>
        {message.message}
      </Alert>
    </Snackbar>
  );
}
