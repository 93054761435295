import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
// recoil
import { useRecoilValue } from 'recoil';
import { authStore } from 'src/recoil';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard')
};

// ----------------------------------------------------------------------

export function useNavData() {
  const authenticated = useRecoilValue(authStore);
  const userLevel = Number(authenticated?.LEVEL) || 0;

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------

      {
        subheader: 'OVERVIEW',
        items: [{ title: '대시보드', path: paths.dashboard.root, icon: ICONS.analytics }]
      },

      // 예약 관리 메뉴
      {
        subheader: '',
        items: [{ title: '예약 관리', path: paths.dashboard.booking.root, icon: ICONS.booking, children: [{ title: '예약 관리', path: paths.dashboard.booking.list }] }]
      },

      // 리뷰 관리 메뉴
      // {
      //   subheader: '',
      //   items: [{ title: '리뷰 관리', path: paths.dashboard.review.root, icon: ICONS.chat, children: [{ title: '리뷰 관리', path: paths.dashboard.review.list }] }]
      // },

      // 기업 크레딧 관리
      {
        subheader: '',
        items: [
          {
            title: '기업 크레딧 관리',
            path: paths.dashboard.credit.root,
            icon: ICONS.banking,
            children: [
              { title: '크레딧 충전/환불', path: paths.dashboard.credit.charge },
              { title: '크레딧 지급/회수', path: paths.dashboard.credit.payment },
              { title: '크레딧 사용내역', path: paths.dashboard.credit.refund }
            ]
          }
        ]
      },

      // 기업 상품지정 관리
      {
        subheader: '',
        items: [{ path: paths.dashboard.product.root, icon: ICONS.order, title: '상품지정 관리' }]
      },

      // 할인쿠폰 관리
      {
        subheader: '',
        items: [
          {
            title: '쿠폰 관리',
            path: paths.dashboard.coupon.root,
            icon: ICONS.label,
            children: [
              { title: '쿠폰 등록/지급', path: paths.dashboard.coupon.payment },
              { title: '쿠폰 사용/지급 내역', path: paths.dashboard.coupon.list }
            ]
          }
        ]
      },

      // 기업 정보 관리 (레벨 10만 가능)
      ...(userLevel === 10
        ? [
            {
              subheader: '',
              items: [
                {
                  title: '기업 정보 관리',
                  path: paths.dashboard.info.root,
                  icon: ICONS.job,
                  children: [
                    { title: '기업 정보수정', path: paths.dashboard.info.edit },
                    { title: '기업 임직원관리', path: paths.dashboard.info.employee },
                    { title: '담당 관리자 설정', path: paths.dashboard.info.manager },
                    { title: '기업 임직원 대량등록', path: paths.dashboard.info.employeeMassAdd }
                  ]
                }
              ]
            }
          ]
        : [])
    ],
    [userLevel] // userLevel이 변경될 때마다 useMemo를 다시 계산합니다.
  );

  return data;
}
