import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { UserGuard } from 'src/components/auth-guard/user-guard';
// layouts
import AdminLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// 회원 관리
const UserCommonPage = lazy(() => import('src/pages/dashboard/user/common'));
const UserAddCommonPage = lazy(() => import('src/pages/dashboard/user/common-add'));
const UserDetailCommonPage = lazy(() => import('src/pages/dashboard/user/common-detail'));

const UserCompanyPage = lazy(() => import('src/pages/dashboard/user/company'));
const UserDetailCompanyPage = lazy(() => import('src/pages/dashboard/user/company-detail'));

const UserPartnerPage = lazy(() => import('src/pages/dashboard/user/partner'));
const UserAddPartnerPage = lazy(() => import('src/pages/dashboard/user/partner-add'));
const UserDetailPartnerPage = lazy(() => import('src/pages/dashboard/user/partner-detail'));

//  기업 정보 관리
const InfoEditPage = lazy(() => import('src/pages/dashboard/info/edit'));
const InfoEmployeePage = lazy(() => import('src/pages/dashboard/info/employee'));
const InfoManagerPage = lazy(() => import('src/pages/dashboard/info/manager'));
const InfoEmployeeDetailPage = lazy(() => import('src/pages/dashboard/info/employee-detail'));
const InfoEmployeeAddPage = lazy(() => import('src/pages/dashboard/info/employee-add'));
const InfoEmployeeGroupSettingPage = lazy(() => import('src/pages/dashboard/info/employee-group-setting'));
const InfoEmployeeGroupDetailPage = lazy(() => import('src/pages/dashboard/info/employee-group-detail'));
const InfoEmployeeMassAddPage = lazy(() => import('src/pages/dashboard/info/employee-mass-add'));

// 예약 관리
const BookingListPage = lazy(() => import('src/pages/dashboard/booking/booking-list'));
const BookingListAddPage = lazy(() => import('src/pages/dashboard/booking/booking-list-add'));
const BookingListDetailPage = lazy(() => import('src/pages/dashboard/booking/booking-list-detail'));
const BookingPaymentPage = lazy(() => import('src/pages/dashboard/booking/booking-payment'));

// 리뷰 관리
// const ReviewListPage = lazy(() => import('src/pages/dashboard/review/list'));
// const ReviewAddPage = lazy(() => import('src/pages/dashboard/review/add'));
// const ReviewDetailPage = lazy(() => import('src/pages/dashboard/review/list-detail'));

// 기업 크레딧 관리
const CreditChargePage = lazy(() => import('src/pages/dashboard/credit/charge'));
const CreditPaymentPage = lazy(() => import('src/pages/dashboard/credit/payment'));
const CreditUsePage = lazy(() => import('src/pages/dashboard/credit/use'));
const CreditPaymentManagePage = lazy(() => import('src/pages/dashboard/credit/payment-manage'));
// const CreditRecoveryManagePage = lazy(() => import('src/pages/dashboard/credit/recovery-manage'));

// 상품 지정 관리
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));

// 할인쿠폰 관리
const CouponListPage = lazy(() => import('src/pages/dashboard/coupon/list'));
const CouponPaymentPage = lazy(() => import('src/pages/dashboard/coupon/payment'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'admin',
    element: (
      <UserGuard>
        <AdminLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AdminLayout>
      </UserGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { element: <UserCommonPage />, index: true },
          // 일반회원
          { path: 'common', element: <UserCommonPage /> },
          { path: 'common/detail/:id', element: <UserDetailCommonPage /> },
          { path: 'common/add', element: <UserAddCommonPage /> },
          // 기업회원
          { path: 'company', element: <UserCompanyPage /> },
          { path: 'company/detail/:id', element: <UserDetailCompanyPage /> },
          // 파트너회원
          { path: 'partner', element: <UserPartnerPage /> },
          { path: 'partner/add', element: <UserAddPartnerPage /> },
          { path: 'partner/detail/:id', element: <UserDetailPartnerPage /> }
        ]
      },

      //  기업 정보 관리
      {
        path: 'info',
        children: [
          { element: <InfoEditPage />, index: true },
          { path: 'edit', element: <InfoEditPage /> },
          { path: 'employee', element: <InfoEmployeePage /> },
          { path: 'manager', element: <InfoManagerPage /> },
          { path: 'employee/:id', element: <InfoEmployeeDetailPage /> },
          { path: 'employee/add', element: <InfoEmployeeAddPage /> },
          { path: 'employee/group', element: <InfoEmployeeGroupSettingPage /> },
          { path: 'employee/group/:id', element: <InfoEmployeeGroupDetailPage /> },
          { path: 'employee/mass/add', element: <InfoEmployeeMassAddPage /> }
        ]
      },

      // 예약 관리
      {
        path: 'booking',
        children: [
          { element: <BookingListPage />, index: true },
          { path: 'list/:category', element: <BookingListPage /> },
          { path: 'list/add/:id', element: <BookingListAddPage /> },
          { path: 'list/detail/:id', element: <BookingListDetailPage /> },
          { path: 'payment/:id', element: <BookingPaymentPage /> }
        ]
      },

      // 리뷰 관리
      // {
      //   path: 'review',
      //   children: [
      //     { element: <ReviewListPage />, index: true },
      //     { path: 'list', element: <ReviewListPage /> },
      //     { path: 'add/:id', element: <ReviewAddPage /> },
      //     { path: 'detail/:id', element: <ReviewDetailPage /> }
      //   ]
      // },

      // 기업 크레딧 관리
      {
        path: 'credit',
        children: [
          { element: <CreditChargePage />, index: true },
          { path: 'charge', element: <CreditChargePage /> },
          { path: 'payment', element: <CreditPaymentPage /> },
          { path: 'use', element: <CreditUsePage /> },
          { path: 'payment/manage', element: <CreditPaymentManagePage /> }
          // 임시 주석처리
          // { path: 'recovery/manage', element: <CreditRecoveryManagePage /> }
        ]
      },

      // 할인쿠폰 관리
      {
        path: 'coupon',
        children: [
          { element: <CouponListPage />, index: true },
          { path: 'payment', element: <CouponPaymentPage /> },
          { path: 'list', element: <CouponListPage /> }
        ]
      },

      // 상품 지정 관리
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> }
        ]
      }
    ]
  }
];
