import { useCallback, useEffect } from 'react';
// routes
import { useRecoilValue } from 'recoil';
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { authStore } from '../../recoil';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export const UserGuard = ({ children }: Props) => {
  const router = useRouter();

  const authenticated = useRecoilValue(authStore);

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || paths.auth.jwt.login;

  const check = useCallback(() => {
    if (!authenticated || !authenticated.EMAIL) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
};
