import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// Toast 메시지를 관리하는 recoil atom
export const orgNumberStore = atom({
  key: 'orgNumber',
  default: 0,
  effects_UNSTABLE: [persistAtom]
});
