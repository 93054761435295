const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/admin'
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`
    }
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      common: `${ROOTS.DASHBOARD}/user/common`,
      company: `${ROOTS.DASHBOARD}/user/company`,
      partner: `${ROOTS.DASHBOARD}/user/partner`,
      commonAddUser: `${ROOTS.DASHBOARD}/user/common/add`
      // details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      // edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      // demo: {
      //   details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
      //   edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      // },
    },
    info: {
      root: `${ROOTS.DASHBOARD}/info`,
      edit: `${ROOTS.DASHBOARD}/info/edit`,
      employee: `${ROOTS.DASHBOARD}/info/employee`,
      manager: `${ROOTS.DASHBOARD}/info/manager`,
      employeeMassAdd: `${ROOTS.DASHBOARD}/info/employee/mass/add`
    },
    booking: {
      root: `${ROOTS.DASHBOARD}/booking`,
      list: `${ROOTS.DASHBOARD}/booking/list/ALL`,
      confirm: `${ROOTS.DASHBOARD}/booking/confirm`,
      complete: `${ROOTS.DASHBOARD}/booking/complete`,
      cancel: `${ROOTS.DASHBOARD}/booking/cancel`
    },
    branch: {
      root: `${ROOTS.DASHBOARD}/branch`,
      list: `${ROOTS.DASHBOARD}/branch/list`,
      approve: `${ROOTS.DASHBOARD}/branch/approve`
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      list: `${ROOTS.DASHBOARD}/product/list`,
      approve: `${ROOTS.DASHBOARD}/product/approve`
    },
    facility: {
      root: `${ROOTS.DASHBOARD}/facility`
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      workation: `${ROOTS.DASHBOARD}/order/workation`,
      community: `${ROOTS.DASHBOARD}/order/community`,
      shopping: `${ROOTS.DASHBOARD}/order/shopping`
    },
    credit: {
      root: `${ROOTS.DASHBOARD}/credit`,
      charge: `${ROOTS.DASHBOARD}/credit/charge`,
      payment: `${ROOTS.DASHBOARD}/credit/payment`,
      refund: `${ROOTS.DASHBOARD}/credit/use`,
      recovery: `${ROOTS.DASHBOARD}/credit/recovery`
    },
    calculate: {
      root: `${ROOTS.DASHBOARD}/calculate`,
      history: `${ROOTS.DASHBOARD}/calculate/history`,
      setting: `${ROOTS.DASHBOARD}/calculate/setting`
    },
    // review: {
    //   root: `${ROOTS.DASHBOARD}/review`,
    //   list: `${ROOTS.DASHBOARD}/review/list`,
    //   sns: `${ROOTS.DASHBOARD}/review/sns`
    // },
    inquiry: {
      root: `${ROOTS.DASHBOARD}/inquiry`,
      company: `${ROOTS.DASHBOARD}/inquiry/company`,
      partner: `${ROOTS.DASHBOARD}/inquiry/partner`
    },
    homepage: {
      root: `${ROOTS.DASHBOARD}/homepage`,
      faq: `${ROOTS.DASHBOARD}/homepage/faq`,
      news: `${ROOTS.DASHBOARD}/homepage/news`
    },
    coupon: {
      root: `${ROOTS.DASHBOARD}/coupon`,
      list: `${ROOTS.DASHBOARD}/coupon/list`,
      payment: `${ROOTS.DASHBOARD}/coupon/payment`
    }
  },

  page403: '/403',
  page404: '/404',
  page500: '/500'
};
