import { atom } from 'recoil';

import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export interface authInterface {
  EMAIL: string;
  TYPE: string;
  LEVEL: string;
  NAME: string;
  TOKEN: string;
}

export const authStore = atom<authInterface>({
  key: 'account',
  default: { EMAIL: '', TYPE: '', LEVEL: '', NAME: '', TOKEN: '' },
  effects_UNSTABLE: [persistAtom]
});
